import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/logo.jpg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { styles } from '../styles';
import { withStyles, WithStyles } from '@material-ui/styles';

const OnTopWrapper = styled.div`
  z-index: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WelcomeMessage = styled.div`
  margin-top: 60px;
  img {
    width: 236px;
    height: 108px;
  }
`;

const Icon = styled.img``;

const RebuildInfo = styled.div`
  display:flex;
  gap: 10px;
  background-color:#F0F9FF;
  border-radius: 12px;
  padding: 16px;
`;

const RebuildParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const RebuildParagraph = styled.p`
  margin:0;
  padding:0;
`;

const RebuildInfoWrapper = styled.div`
  display:flex;
  flex-direction:column;
  padding: 0 16px;
  gap: 10px;
  flex-grow: 1;
  gap: 20px;
  border-radius: 12px;
  max-width: 450px;
`;

const RebuildContactLink = styled.a`
  background-color: #0EA5E9;
  border-radius: 30px;
  padding:  14px 20px;
  margin-bottom: 30px;
  display:flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  color: #FAFAFA;
  &:active {
    text-decoration: none;
  }
`;

const RebuildSvg = styled.div`
 display:flex;
 flex-direction: column;
 h2 {
  margin:0;
  padding:0;
  text-align: center;
  margin-bottom: 30px;
 }
`;

const WelcomeComponent = (props: WithStyles<typeof styles>) => {
  return (
    <OnTopWrapper className={props.classes.root}>
      <WelcomeMessage>
        <Icon src={Logo} />
      </WelcomeMessage>
      <RebuildSvg>
        <svg
          width="291"
          height="253"
          viewBox="0 0 291 253"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          transform="scale(.8)"
        >
          <path
            d="M57.6479 76.1277C93.853 68.4643 114.717 34.4388 119.524 29.5342C143.865 -7.96536 191.73 0.140803 207.991 2.45685C258.89 12.3001 350.183 85.4918 236.283 195.808C109.1 318.988 13.4674 210.196 3.34009 172.073C-5.13174 140.183 -0.9823 88.5377 57.6479 76.1277Z"
            fill="#F0F9FF"
          />
          <g opacity="0.3">
            <g clip-path="url(#clip0_27_11)">
              <path
                d="M180.401 116.149C180.401 115.051 179.989 113.971 179.175 113.148C177.528 111.501 174.838 111.501 173.181 113.148L103.556 182.773C102.733 183.596 102.321 184.685 102.321 185.783C102.312 186.854 102.724 187.943 103.547 188.776C105.194 190.423 107.912 190.423 109.55 188.776L179.184 119.15C180.017 118.318 180.428 117.238 180.41 116.158L180.401 116.149ZM105.249 187.074C104.499 186.323 104.499 185.106 105.249 184.356C105.999 183.606 107.226 183.596 107.976 184.356C108.726 185.115 108.735 186.332 107.976 187.083C107.216 187.833 106.009 187.833 105.249 187.083V187.074Z"
                fill="#3C4A50"
              />
              <path
                d="M129.168 157.161L103.556 182.773C102.733 183.596 102.321 184.685 102.321 185.783C102.312 186.854 102.724 187.943 103.547 188.776C105.194 190.423 107.912 190.423 109.55 188.776L135.162 163.164L129.168 157.17V157.161ZM105.249 187.074C104.499 186.323 104.499 185.106 105.249 184.356C105.999 183.606 107.226 183.596 107.976 184.356C108.726 185.115 108.735 186.332 107.976 187.083C107.216 187.833 106.009 187.833 105.249 187.083V187.074Z"
                fill="#698AE6"
              />
              <g>
                <path
                  d="M179.174 113.148L107.975 184.347C107.225 183.596 106.008 183.596 105.249 184.347C104.489 185.097 104.498 186.314 105.249 187.064L103.547 188.766C102.714 187.934 102.302 186.854 102.32 185.774C102.32 184.676 102.732 183.587 103.556 182.764L173.181 113.148C174.828 111.501 177.518 111.501 179.174 113.148Z"
                  fill="#D0D1D3"
                />
              </g>
              <path
                d="M158.22 74.9999L150.497 80.2248L212.098 141.825L217.322 134.102L158.22 74.9999Z"
                fill="#3C4A50"
              />
              <path
                d="M165.284 120.029L151.623 85.5961L206.726 140.7L172.284 127.038L165.284 120.029Z"
                fill="#A6CEF4"
              />
              <path
                d="M212.693 141.496L209.828 144.36C208.84 145.348 207.211 145.348 206.214 144.36L147.972 86.0993C146.975 85.1019 146.984 83.4823 147.972 82.4849L150.836 79.6208C151.833 78.6234 153.453 78.6143 154.45 79.6208L212.711 137.881C213.699 138.869 213.699 140.498 212.711 141.496H212.693Z"
                fill="#65C1B0"
              />
              <path
                d="M212.692 141.496L211.905 142.282C210.917 143.271 209.288 143.271 208.291 142.282L150.04 84.0221C149.043 83.0248 149.052 81.4051 150.04 80.4078L150.827 79.6208C151.824 78.6234 153.444 78.6143 154.441 79.6208L212.702 137.881C213.69 138.869 213.69 140.498 212.702 141.496H212.692Z"
                fill="#7DDACB"
              />
              <path
                d="M101.928 89.796L101.955 89.8235C100.216 92.3124 98.3772 94.6274 96.4831 96.7869C95.2936 98.132 94.0766 99.4222 92.8596 100.639C88.4582 105.041 83.9929 108.563 80.2138 111.208L80.9183 109.634L81.1471 109.149L78.9693 110.705C77.368 108.911 76.087 106.788 75.3732 104.464L76.9288 103.705L75.0255 103.146C74.6961 101.701 74.5954 100.2 74.751 98.6536L76.9105 97.7202L74.9523 97.4274C75.236 95.9634 75.785 94.4993 76.6085 93.017L78.6948 93.383L77.2674 91.9555C77.8621 91.0496 78.5576 90.1437 79.4085 89.247C80.3053 88.396 81.2112 87.7006 82.1262 87.0967L83.5536 88.5241L83.1876 86.4378C83.9746 86.0078 84.7432 85.6509 85.5118 85.3673C86.2073 85.111 86.9118 84.9189 87.5981 84.7816L87.8818 86.749L88.8151 84.5895C90.3615 84.4248 91.8713 84.5254 93.3079 84.8548L93.8661 86.7581L94.6256 85.2025C96.9498 85.9163 99.0727 87.1973 100.866 88.7986L99.3106 90.9764L100.363 90.5006L101.918 89.796H101.928Z"
                fill="#DA4D23"
              />
              <path
                d="M101.928 89.7961L104.92 92.7883L103.849 94.4811L105.734 93.6118L108.799 96.6772L107.692 98.3243L109.843 97.7204L114.5 102.378C112.313 105.526 109.925 108.399 107.482 111.007C106.786 111.748 106.091 112.48 105.386 113.184C100.802 117.769 96.1445 121.42 92.283 124.101L87.8726 119.69L88.4765 117.54L86.8294 118.647L83.7641 115.582L84.6333 113.697L82.9405 114.767L79.9484 111.775L80.1954 111.217L80.9 109.643C85.1275 106.779 90.9471 102.296 96.0438 96.302C97.5628 94.5086 99.0269 92.5687 100.363 90.5099L101.918 89.8053L101.928 89.7961Z"
                fill="#698AE6"
              />
              <path
                d="M113.786 101.655L115.507 103.375L113.951 105.617L116.623 104.501L118.892 106.77L118.581 108.747L120.045 107.914L122.379 110.247L122.003 111.592L123.596 111.464L125.307 113.175C125.654 113.523 126.002 113.871 126.313 114.209L127.009 114.941C124.209 118.986 121.098 122.573 117.941 125.73C117.318 126.352 116.696 126.956 116.074 127.532C112.112 131.275 108.186 134.304 104.856 136.619C104.636 136.399 104.38 136.17 104.151 135.941C103.877 135.685 103.611 135.42 103.346 135.145L101.635 133.434L101.763 131.842L100.418 132.217L98.0844 129.884L98.9079 128.429L96.9315 128.731L94.6622 126.462L95.7785 123.79L93.5367 125.345L91.606 123.415C95.5132 120.944 101.626 116.579 107.143 110.503C109.541 107.886 111.828 104.922 113.777 101.664L113.786 101.655Z"
                fill="#F2BB59"
              />
              <path
                d="M126.322 114.218L127.018 114.95C127.192 115.152 127.384 115.335 127.558 115.536L127.036 117.659L129.077 117.275C130.815 119.352 132.069 121.191 132.956 122.811L130.486 123.863L133.698 124.284C134.173 125.327 134.466 126.26 134.649 127.084L132.005 128.072L134.896 128.786C134.997 131.43 133.762 132.556 133.762 132.556L129.333 139.171L122.717 143.6C122.717 143.6 121.601 144.836 118.947 144.735L118.49 142.887L118.224 141.843L117.245 144.479C116.413 144.296 115.488 144.003 114.445 143.527L114.015 140.315L112.972 142.777C111.352 141.889 109.513 140.636 107.436 138.897L107.82 136.856L105.697 137.378C105.423 137.131 105.139 136.875 104.856 136.619C104.636 136.399 104.38 136.17 104.151 135.941C107.171 134.029 111.49 130.982 115.845 126.91C119.569 123.415 123.33 119.169 126.313 114.218H126.322Z"
                fill="#7DDACB"
              />
              <g>
                <path
                  d="M101.927 89.7961L104.92 92.7883L103.849 94.4811L105.734 93.6118L108.799 96.6772L107.692 98.3243L109.842 97.7203L115.506 103.384L113.951 105.626L116.623 104.51L118.892 106.779L118.581 108.756L120.045 107.923L122.378 110.256L122.003 111.601L123.595 111.473L125.307 113.184C125.654 113.532 126.002 113.88 126.313 114.218L127.008 114.95C127.182 115.152 127.374 115.335 127.548 115.536L127.027 117.659L129.067 117.275C130.806 119.352 132.059 121.191 132.947 122.811L130.476 123.863L133.688 124.284C134.164 125.327 134.457 126.26 134.64 127.084L131.995 128.072L134.887 128.786C134.988 131.43 133.752 132.556 133.752 132.556L129.324 139.171L122.708 143.6C122.708 143.6 121.591 144.836 118.938 144.735L118.48 142.887C118.865 137.881 117.913 132.693 116.065 127.532C116.001 127.322 115.918 127.111 115.836 126.901C113.823 121.447 110.849 116.049 107.472 110.988C107.363 110.814 107.244 110.65 107.134 110.476C103.767 105.48 100.033 100.831 96.4646 96.7687C96.3182 96.604 96.1809 96.4484 96.0345 96.2746C92.0083 91.736 88.2475 87.966 85.4933 85.3582C86.1887 85.102 86.8933 84.9098 87.5796 84.7726L87.8632 86.7399L88.7966 84.5804C90.343 84.4157 91.8528 84.5164 93.2894 84.8458L93.8476 86.749L94.607 85.1935C96.9312 85.9072 99.0541 87.1883 100.848 88.7896L99.292 90.9674L100.344 90.4915L101.9 89.787L101.927 89.7961Z"
                  fill="#E6E6E7"
                />
              </g>
              <path
                d="M133.386 139.547L179.175 185.335C179.806 185.966 179.907 186.909 179.385 187.421L177.848 188.959C177.326 189.48 176.393 189.379 175.762 188.748L129.973 142.96C129.699 142.685 129.516 142.347 129.47 141.999C129.379 141.587 129.47 141.157 129.763 140.864L131.3 139.327C131.822 138.805 132.755 138.906 133.386 139.537V139.547Z"
                fill="#3C4A50"
              />
              <path
                d="M133.789 132.537C133.789 132.537 135.28 136.701 133.981 140.151C133.679 140.956 133.222 141.734 132.554 142.392C131.986 142.969 131.346 143.371 130.669 143.664C127.164 145.211 122.726 143.609 122.726 143.609L133.798 132.537H133.789Z"
                fill="#3C4A50"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_27_11">
              <rect
                width="142.645"
                height="115.011"
                fill="white"
                transform="translate(74.6775 75)"
              />
            </clipPath>
          </defs>
        </svg>
        <h2>Förbättring pågår...</h2>
      </RebuildSvg>
      <RebuildInfoWrapper>
        <RebuildInfo>
          <InfoOutlinedIcon style={{ color: '#0EA5E9' }} />
          <RebuildParagraphWrapper>
            <RebuildParagraph>
              Just nu arbetar vi med att förbättra våra system och utför därför
              ett större underhåll för både kundapp och mina sidor.
            </RebuildParagraph>
            <RebuildParagraph>
              Har du frågor eller vill göra ändringar i ditt abonnemang?
              Kontakta kundservice så hjälper vi dig.
            </RebuildParagraph>
          </RebuildParagraphWrapper>
        </RebuildInfo>
        <RebuildContactLink href="/contactInfo">
          Gå till kontakt
        </RebuildContactLink>
      </RebuildInfoWrapper>
    </OnTopWrapper>
  );
};

export const Welcome = withStyles(styles)(WelcomeComponent);
