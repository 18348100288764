import React from 'react';
import styled from 'styled-components';
import { Header } from '../../Components/Header';
import { Call, Email, Language } from '@material-ui/icons';
import { teal } from '../../utils/constants/colors';
import { Title, SubHeader, SubLine } from '../../Components/Common';

const ProfileContainer = styled.div`
  height: calc(100% - 140px);
  width: 100%;
  color: black;
  margin-top: 70px;
  @media screen and (min-width: 768px) {
    margin-top: 99px;
  }
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const ContactType = styled.div`
  margin: 0 0 20px 0;
`;

const ProfileSection = styled.div`
  padding: 0 20px 20px 20px;
`;

const IndentedDetails = styled.div`
  margin-left: 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Info = styled.div`
  margin-left: 5px;
`;

const StyledAnchor = styled.a`
  color: ${teal};
`;

export const ContactInfoRebuild = () => {
    return (
      <>
        <Header />
        <ProfileContainer>
          <Title>Kontakt</Title>
          <ProfileSection>
            <IndentedDetails>
              <ContactType>
                <SubHeader>
                  Webbplats<SubLine>Om du vill söka information själv.</SubLine>
                </SubHeader>
                <InfoWrapper>
                  <Language fontSize="small" color="primary" />
                  <Info>
                    <StyledAnchor
                      target="_blank"
                      href="https://eriksfonsterputs.se"
                    >
                      https://eriksfonsterputs.se
                    </StyledAnchor>
                  </Info>
                </InfoWrapper>
              </ContactType>
              <ContactType>
                <SubHeader>
                  E-post
                  <SubLine>
                    Om ditt ärende inte är akut
                  </SubLine>
                </SubHeader>
                <InfoWrapper>
                  <Email fontSize="small" color="primary" />
                  <Info>
                    <StyledAnchor href="mailto:kundtjanst@eriksfonsterputs.se">
                      kundtjanst@eriksfonsterputs.se
                    </StyledAnchor>
                  </Info>
                </InfoWrapper>
              </ContactType>
              <ContactType>
                <SubHeader>
                  Telefon (09-18)
                  <SubLine>
                    Om du inte kan lösa ditt ärende med ovanstående.
                  </SubLine>
                </SubHeader>
                <InfoWrapper>
                  <Call fontSize="small" color="primary" />
                  <Info>
                    <StyledAnchor href="tel:0771424242">
                      0771-42 42 42
                    </StyledAnchor>
                  </Info>
                </InfoWrapper>
              </ContactType>
            </IndentedDetails>
          </ProfileSection>
        </ProfileContainer>
      </>
    );
}
