import { CssBaseline } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ContactInfoRebuild } from './Views/ContactInfo/ContactInfoRebuild';
import { Welcome } from './Views/WelcomeRebuild';

export const AppRebuild = () => {
  return (
    <Fragment>
      <CssBaseline />
      <Switch>
        <Route
          exact
          path="/"
          render={(_props) => {
            return <Welcome />;
          }}
        />
        <Route path="/contactInfo" component={ContactInfoRebuild} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Fragment>
  );
};
