import React from 'react';
import styled from 'styled-components';
import { appWidth } from '../utils/constants/sizes';
import { teal, white } from '../utils/constants/colors';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
// import { LogoutButton } from './buttons';
import Logo from '../assets/logo-inverted.png';
// import { useHistory } from 'react-router-dom';
//import none from 'ramda/es/none';

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  text-align: center;
  background-color: ${teal};
  color: ${white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  & > button {
    position: absolute;
    top: 16px;
    right: 20px;
    min-height: 35px;
    font-size: 14px;
    text-transform: none;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
      0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
      0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  }
  @media screen and (min-width: 768px) {
    height: 100px;
    & > button {
      right: 20px;
      top: 32px;
    }
  }
  @media screen and (min-width: 1024px) {
    width: ${appWidth}px;
  }
`;

const Navigation = styled.div`
  margin: 0;
  position: absolute;
  left: 5px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const NavigationText = styled.div`
  margin: auto 0;
  font-size: 1.5em;
  font-family: Lato Black, Helvetica, Arial, sans-serif;
`;

const Icon = styled.img`
  max-height: 60px;
  margin: 5px 0;
  @media screen and (min-width: 768px) {
    /* right: calc((100vw - 1024px) / 2); */
    max-height: 90px;
  }
`;

const Placeholder = styled.div`
  width: 48px;
`;

type HeaderProps = {
  onBack?: () => void;
  onLogout?: () => void;
  text?: string;
};

export const Header = (props: HeaderProps) => {
  // const history = useHistory();

  // const doLogout = () => {
  //   //window.location.href = "/logout";
  //   history.push(`/logout${history.location.search}`, { doingLogout: true });
  // };

  return (
    <HeaderContainer>
      <Navigation>
        {props.onBack ? (
          <IconButton onClick={() => props.onBack!()}>
            <ArrowBack color="inherit" style={{ color: white }} />
          </IconButton>
        ) : (
          <Placeholder />
        )}
        {props.text && <NavigationText>{props.text}</NavigationText>}
      </Navigation>
      <Icon src={Logo} />
      {/* <LogoutButton onClick={doLogout} /> */}
    </HeaderContainer>
  );
};
